$cluster-main-color: $primary;

.marker-cluster-small {
  background-color: rgba($cluster-main-color, 0.4);
}
.marker-cluster-small div {
  background-color: rgba($cluster-main-color, 0.8);
}

.marker-cluster-medium {
  background-color: rgba($cluster-main-color, 0.4);
}
.marker-cluster-medium div {
  background-color: rgba($cluster-main-color, 0.8);
}

.marker-cluster-large {
  background-color: rgba($cluster-main-color, 0.4);
}
.marker-cluster-large div {
  background-color: rgba($cluster-main-color, 0.8);
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}
.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;

  text-align: center;
  border-radius: 15px;
  @extend %barlow-condensed;
  @include fluid-type($min_width, $max_width, (18px/1.2), 18px, 1.4/1.2, 1.4);
  color: $white;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  backface-visibility: hidden;
  &:hover {
    transform: scale(1.25);
  }
}
.marker-cluster span {
  line-height: 30px;
}