.topbar {
  background-color: #eeeeee;
  max-height: 68px;
  display: flex;
  align-items: center;
  transition: 0.3s;

  .phone-number {
    display: flex;
    margin-right: auto;
    text-transform: uppercase;
    span {
      margin-right: 10px;
      display: none;
    }
  }
}

.topbar-wrapper {
  display: flex;
  align-items: center;
  @include fluid-type($min_width, $max_width, (19px/1.125), 19px, (1.2/1.125), 1.2);
}

ul.nav-topbar {
  padding: 0;
  margin: 0;
  display: none;

  li {
    display: inline-block;
    padding: 0 0.25rem;
    a {
      position: relative;
      display: inline-block;
      padding: 0.5rem 0.6rem;
      text-decoration: none;
      color: $body-color;
    }

    &:hover {
      a {
        &:hover {
          color: $green-dark;
        }
      }
    }

    &.highlighted {
      padding: 0 0.75rem;
      a {
        border-radius: 1.5rem;
        color: #747474;
        border: 1px solid rgba(#747474,0.5);
        @include transition(0.3s);

        &:before {
          background-color: $green-dark;
          content: "Tip";
          display: inline-block;
          padding: 0.475rem 1rem;
          margin-right: 0.75rem;
          border-radius: 16px;
          @include fluid-type($min_width, $max_width, (15px/1.125), 15px, 1, 1);
          color: $white;
          font-weight: 700;
          text-transform: uppercase;
          @include transition(0.3s);
        }

        &:hover {
          background-color: $green-dark;
          color: $white;
          border-color: $green-dark;
          &:before {
            background-color: $white;
            color: $green-dark;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .topbar {
    .container-fluid {
      padding-right: 0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .topbar-wrapper {
    margin: 0 1rem;
  }
}

@include media-breakpoint-up(lg) {
  .topbar {
    height: 64px;
    .phone-number {
      span {
        display: block;
      }
    }
  }
  .topbar-wrapper {
    margin: 0 2rem;
  }
}

@include media-breakpoint-up(xl) {

  ul.nav-topbar {
    display: block;
  }
}