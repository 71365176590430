// Breadcrumbs

$breadcrumb-padding-y:              1.25rem;
$breadcrumb-padding-x:              0rem;
$breadcrumb-item-padding:           1rem;

$breadcrumb-margin-bottom:          0;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          #bebebe;
$breadcrumb-active-color:           #bebebe;
$breadcrumb-divider:                quote(">");

$breadcrumb-border-radius:          0;