.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;

  i {
    transition: .3s cubic-bezier(.49,1,.22,1);
    z-index: 0;
  }

  &:hover {
    i {
      color: $primary;
    }
  }
}

.tooltipicon {
  display: block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  max-width: 250px;
  background-color: $primary;
  @include fluid-type($min_width, $max_width, (16px/1.4), 16px, (1.5/1.125), 1.5);
  color: #fff;
  text-align: center;
  padding: 0.5rem 1rem;

  /* Position the tooltip */
  position: absolute;
  bottom: 30px;
  left: 0;
  z-index: 0;
  opacity: 0;
  transition: .2s cubic-bezier(.49,1,.22,1);
}

.tooltip:hover .tooltiptext {
  bottom: 40px;
  visibility: visible;
  display: block;
  opacity: 1;
  z-index: 9;
}