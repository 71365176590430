.quote {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include fluid-type($min_width, $max_width, (34px/1.25), 34px, (1.4/1.125), 1.4);

  &:before {
    content: "";
    background-color: #747474;
    margin: 0 0 3rem 0;
    width: 25%;
    height: 1px;
  }

  &:after {
    content: "";
    background-color: #747474;
    margin: 3rem 0 0 0;
    width: 25%;
    height: 1px;
  }

  &__image {
    margin: 0 2rem 2rem;
    min-width: 50%;
    figure {
      border-radius: 50%;
    }
  }

  &__description {
    span {
      @extend %barlow-condensed;
      display: inline-block;
      padding-top: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }
}

@include media-breakpoint-up(sm) {
  .quote {
    &__image {
      min-width: 30%;
    }
  }
}

@include media-breakpoint-up(md) {
  .quote {
    flex-direction: row;

    &:before {
      margin: 0;
    }

    &:after {
      margin: 0;
    }

    &__image {
      margin: 0 2rem;
      min-width: 20%;
    }

    &__description {
      margin-right: 1rem;

      span {
        padding-top: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .quote {

    &__image {
      min-width: 10%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .quote {
    &:before {
      width: 15%;
    }

    &:after {
      width: 15%;
    }

    &__image {
      min-width: 10%;
    }
  }
}

blockquote{
  width: fit-content;
  margin: 100px auto;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.3;
  &:before, &:after{
    position: relative;
    height: 50px;
    content: '';
    background: url("../images/quote-open.svg");
    width: 45px;
    background-size: contain;
    display: block;
  }
  &:before{
    margin-top: -50px;
    margin-left: 0px;
  }
  &:after{
    margin-top: -45px;
    margin-right: 0px;
    float: right;
  }
}