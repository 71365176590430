nav {
    ul.pagination {
        margin: 3rem 0;
    }
    li.page-item {
        margin: 0 0.25rem;
        a {
            //@include fluid-type($min_width, $max_width, (24px/1.25), 24px, 1, 1);
            line-height: 24px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
        }
        &.first, &.last {
            a.page-link {
                &:not(:hover){
                    background-color: transparent;
                    color: $blue-light;
                    border-color: rgba($body-color,0.3);
                }
            }
        }
        &.previous, &.next {
            margin: 0 1rem;
            a.page-link {
                padding-right: 1.75rem;
                padding-left: 1.75rem;
                width: auto;

                &:not(:hover){
                    background-color: transparent;
                    color: $blue-light;
                    border-color: rgba($body-color,0.3);
                }
            }
        }
        a.page-link {
            height: 48px;
            width: 48px;
            border-radius: 24px!important;
            position: relative;
            vertical-align: middle;
        }
        &.disabled {
            a.page-link {
                .custom-arrow {
                    &:after {
                        background-color: $white;
                    }
                    &:before {
                        background-color: $gray-300;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    nav {
        li.page-item {
            &.first, &.last {
                display: none;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    nav {
        li.page-item {
            &.previous, &.next {
                a.page-link {
                    padding-right: 0;
                    padding-left: 0;
                    width: 48px;

                    span {
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}