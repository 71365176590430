.intro-wrapper {
  position: relative;
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  .intro {
    padding-bottom: 3rem;

    &:after {
      content: "";
      background-color: $grey;
      position: absolute;
      top: 0;
      height: calc(100% - 2rem);

      z-index: -1;
      display: none;
    }
  }
}

@include media-breakpoint-up(md) {
  .intro-wrapper {
    .intro {
      padding-top: 1rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .intro-wrapper {
    .intro {
      padding-top: 6rem;
      padding-right: 3.5rem;
      &:after {
        display: block;
        right: 1rem;
        width: calc(100% + 1rem);
        height: 130%;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .intro-wrapper {
    .intro {
      padding-right: 3.5rem;
    }
  }
}