.btn {
  display: inline-block;
  padding: 0.5rem 2rem;
  @extend %barlow-semi-condensed;
  @include fluid-type($min_width, $max_width, (25px/1.25), 25px, (1.6/1.25), 1.6);
  color: $white;
  font-weight: 600;
  text-decoration: none;
  border-radius: 23px;
  border: none;
  cursor: pointer;

  &--arrow {
    position: relative;
    min-width: 7px;

    &:after {
      content: "";
      display: inline-block;
      width: 7px;
      height: 7px;
      margin: 0.5rem 0 0.5rem 1rem;
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      transform: rotate(-135deg);
      vertical-align: middle;
    }
  }

  &-primary {
    background-color: $primary;

    &:hover {
      background-color: darken($primary,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-secondary {
    background-color: $secondary;

    &:hover {
      background-color: darken($secondary,10%);
      color: $white;
      text-decoration: none;
    }
  }
}

@include media-breakpoint-up(md) {
  .btn {
    padding: 0.5rem 1rem;
  }
}

@include media-breakpoint-up(lg) {
  .btn {
    padding: 0.5rem 2rem;
    border-radius: 30px;
  }
}