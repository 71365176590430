.theme-wrapper {
    background-color: $grey;
    position: relative;
    margin-top: 2rem;
    z-index: -1;
}

.theme {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    text-align: center;

    &__title {
        display: flex;
        flex-direction: row;
        justify-content: center;

        i {
            margin-right: 1rem;
            @include fluid-type($min_width, $max_width, (50px/1.5), 50px, 1, 1);
        }
    }

    &__text {
        color: #747474;
    }
}

@include media-breakpoint-up(md) {
    .theme-wrapper {
        padding-top: 3rem;
        padding-bottom: 3rem;
        &--offset {
            margin-top: -10rem;
            padding-top: 14rem;
        }
    }

    .theme {
        &__title {
            padding-right: 2rem;
        }

        &__text {
            padding-right: 2rem;
        }
    }
}