$palettes: (

        green: (
                base: #97d14f
        ),
        red: (
                base: #d9534f
        ),
        gray: (
                light: #f8f9fa,
                dark: #343a40
        )

);

$blue: #0371b9;
$blue-light: #2182c1;
$green: #97d14f;
$green-dark: #489783;
$grey: #eeeeee;
$grey-dark: #454545;
$body-color: $grey-dark;

$primary:       $green;
$secondary:     $blue;

$light:         $grey;
$dark:          $grey-dark;

$white:    #fff;
$black:    #000;

$theme-colors: () !default;
$theme-colors: map-merge((
        "dunique-green":    palette(green, base)
), $theme-colors);


// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $black;
$yiq-text-light:            $white;