.owl-carousel {
  .owl-prev,.owl-next {
    position: absolute;
    top: calc(50% - 15px);

    &:focus, &:hover {
      background: transparent!important;
      outline: none;
    }
  }

  .owl-prev {
    left: -2.5rem;
  }

  .owl-next {
    right: -2.5rem;
    transform: rotate(180deg);
  }

  .custom-arrow {
    &:before {
      width: 30px;
      height: 30px;
      border-left: 7px solid #454545;
      border-bottom: 7px solid #454545;
    }
  }
}

@include media-breakpoint-up(sm) {
  .slider {
    .item {
      .figure {
        height: 150px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .owl-carousel {
    .owl-prev {
      left: -2.5rem;
    }

    .owl-next {
      right: -2.5rem;
    }
  }
  .slider {
    .item {
      .figure {
        height: 200px;
      }
      .item-content {
        h2 {

        }
      }
    }
  }

}

@include media-breakpoint-up(lg) {
  .owl-carousel {
    .owl-prev {
      left: -3.5rem;
    }

    .owl-next {
      right: -3.5rem;
    }
  }
  .slider {
    .item {
      .figure {
        height: 300px;
      }

      .item-content {
        h2 {

        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .slider {
    .item {
      .figure {
        height: 400px;
      }

      .item-content {
        h2 {

        }
      }
    }
  }
}

@media (min-width: 1800px) {
  .owl-carousel {
    .owl-prev {
      left: -3.5rem;
    }

    .owl-next {
      right: -3.5rem;
    }
  }
}

body.loaded {
  .slider {
    .item {
      .item-content {
        h2 {
          -webkit-transform: translate(0px);
          transform: translate(0px);
        }
      }
    }
  }
}