.nav-container {
  background-color: $white;
  width: 100%;
  z-index: 99;
  transition: 0.3s;

  .navbar {
    position: relative;
    display: flex;
    align-items: flex-end;

    .navbar-brand {
      padding: 1.5rem 0;
      margin-right: auto;
      display: flex;
      align-items: center;
      font-size: 0;
      line-height: inherit;
      z-index: 0;
      img {
        max-width: 84px;
      }
    }
    .nav {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 0 15px 4px rgba(0,0,0,0.1);
  }
}
ul.nav-desktop {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  z-index: 99;
  display: none;
  > li {
    display: inline-block;

    > a {
      position: relative;
      display: inline-block;
      @include fluid-type($min_width, $max_width, (24px/1.25), 24px, 1, 1);
      color: $body-color;
      font-weight: 400;
      line-height: 96px;
      text-transform: uppercase;
      text-decoration: none;
      transition: 0.3s;

      &:not(.btn){
        padding: 0 0.8rem;
        color: $body-color;
      }
    }

    &.has-subitems {
      position: relative;
      .custom-arrow {
        position: absolute;
        right: .25rem;
        top: 50%;
        transform: translateY(-4px);
        &:before {
          width: 7px;
          height: 7px;
          transform: rotate(-45deg);
        }
      }
      > a {
        &:before {
          width: 100%;
        }
      }
    }

    //mega submenu
    > .submenu {
      background-color: $white;
      position: absolute;
      top: 100%;
      left: 0;
      padding: 1.5rem;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        &.submenu-items {
          margin-left: 0;
          min-width: 200px;
          overflow: hidden;

          @include fluid-type($min_width, $max_width, (20px/1.25), 20px, 1, 1);

          > li {
            position: relative;
            padding: 0.5rem 0;
            transform: translateX(-100%);
            transition: 0.2s;

            a {
              position: relative;
              padding: 0.5rem 2.5rem;
              border-radius: 17.5px;
              color: $body-color;
              text-decoration: none;
              transition: 0.3s;
              white-space: nowrap;
              display: block;
            }

            &.active, &:hover {
              a {
                background-color: $grey;
              }
            }
          }
        }
      }
    }

    //active item
    &.active {
      > a {
        background-color: $grey;
      }
    }

    //open submenu
    &:hover {

      > a:not(.btn) {
        background-color: $grey;
      }

      .submenu {
        box-shadow: 6px 6px 15px 0 rgba(0,0,0,0.1);
        visibility: visible;
        opacity: 1;
        ul {
          > li {
            @for $i from 0 through 10 {
              &:nth-child(#{$i}){
                transition-delay: (#{$i/10}s);
              }
            }
            transform: translateX(0%);
          }
        }
      }
    }
  }
}

#nav-secundary {
  display: none;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0 1rem;

  > li {
    display: inline-block;
    > a {
      padding: 0 2rem;
      display: inline-block;
      font-weight: 600;
      line-height: 40px;
      text-decoration: none;
    }
  }
}

@include media-breakpoint-up(sm) {
  .nav-container {
    .navbar {
      margin: 0 1rem;
      .navbar-brand {
        img {
          max-width: 140px;
        }
      }
    }
  }

  ul.nav-desktop {
    li {
      > a {
        &:not(.btn){

        }
      }
    }
  }

  #nav-secundary {
    display: flex;
    > li {
      > a:not(.btn) {
        padding: 0 2rem;
        line-height: 64px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  body {
    .nav-container {
      &:not(.sticky) {
        //position: absolute;
        //background-color: transparent;
      }
    }
  }

  ul.nav-desktop, #nav-secundary {
    > li {
      &.has-subitems {
        position: relative;
        .custom-arrow {
          right: 1.5rem;
        }
        > a {
          padding-right: 3.5rem;
          &:before {
            width: 100%;
          }
        }
      }
    }
  }

  .nav-container {
    .navbar {
      margin: 0 2rem;
    }
    &.sticky {
      .topbar {
        height: 0;
        opacity: 0;
      }
    }
  }


  ul.nav-desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > li {
      padding: 0.5rem 1rem;

      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.35rem .5rem;
          border-radius: 17.5px;
        }
      }

      &.has-subitems {
        > a {
          padding-right: 3.5rem!important;
        }
      }
    }
  }

  ul#nav-secundary {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  .nav-container {
    .navbar {
      .navbar-brand {
        img {
          max-width: 180px;
        }
      }
    }
  }
  ul.nav-desktop {

    > li {
      padding: 1.25rem 0;
      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.35rem 1.5rem;
          border-radius: 17.5px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .nav-container {
    .navbar {
      .navbar-brand {
        img {
          max-width: 180px;
        }
      }
    }
  }
  ul.nav-desktop {

    > li {
      padding: 1.25rem 0;
      > a {
        &.btn {
          padding: 0.5rem 2rem;
        }
        &:not(.btn){
          padding: 0.35rem 2.5rem;
          border-radius: 17.5px;
        }
      }
      &.has-subitems {
        > a {
          padding-right: 3.5rem;
        }
        .custom-arrow {
          right: 1.25rem;
        }
      }
    }
  }
}