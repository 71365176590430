@font-face {
	font-family: "accres-icons";
	src: url('../fonts/accres-icons.eot');
	src: url('../fonts/accres-icons.eot?#iefix') format('eot'),
		url('../fonts/accres-icons.woff2') format('woff2'),
		url('../fonts/accres-icons.woff') format('woff'),
		url('../fonts/accres-icons.ttf') format('truetype'),
		url('../fonts/accres-icons.svg#accres-icons') format('svg');
}

.accres-icons:before {
	font-family: "accres-icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.accres-icons-betrouwbaar:before {
	content: "\E001";
}

.accres-icons-facebook:before {
	content: "\E002";
}

.accres-icons-gastvrij:before {
	content: "\E003";
}

.accres-icons-instagram:before {
	content: "\E004";
}

.accres-icons-linkedin:before {
	content: "\E005";
}

.accres-icons-lokaal-betrokken:before {
	content: "\E006";
}

.accres-icons-meedenkend:before {
	content: "\E007";
}

.accres-icons-pinterest:before {
	content: "\E008";
}

.accres-icons-toegankelijk:before {
	content: "\E009";
}

.accres-icons-trots:before {
	content: "\E00A";
}

.accres-icons-twitter:before {
	content: "\E00B";
}

.accres-icons-whatsapp:before {
	content: "\E00C";
}

.accres-icons-youtube:before {
	content: "\E00D";
}
