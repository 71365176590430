ul.list-default {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.list-tags {
  padding: 0;
  margin: 0 0 3rem 0;
  list-style: none;
  text-transform: uppercase;
  li {
    display: inline-block;
    margin-right: 1.5rem;
    @include fluid-type($min_width, $max_width, (20px/1.8), 20px, (1.6/1.2), 1.6);
    white-space: nowrap;
  }

  i {
    @include fluid-type($min_width, $max_width, (42px/1.8), 42px, 1.2, 1.2);
    color: $black;
    vertical-align: middle;
  }

  &--small {
    margin: 0;
    li {
      margin-right: 0.15rem;
    }
    i {
      @include fluid-type($min_width, $max_width, (28px/1.125), 28px, (1.2/1.125), 1.2);
    }
  }
}