.video-header {
  position: relative;
  background-color: #fff;

  &__background {
    min-height: 45vw;
    position: relative;
  }

  img {
    width: 100%;
    display: block;
  }

  .video-content {
    position: absolute;
    left: 6rem;
    top: 50%;
    transform: translateY(-50%);
  }

  .video-logo {
    padding: 2rem 1rem .75rem 1rem;
    flex: 1;
    align-items: center;
    display: flex;
    justify-content: center;
    img {
      width: 50%;
      max-width: 50%;
    }
  }

  .video-description {
    position: relative;
    padding: 1rem 1rem;
    color: $white;
    text-align: center;

    &:after {
      content: "";
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 120%;
      z-index: -1;
    }

    .btn {
      margin-top: 1rem;
    }
  }

  .video-overlay {
    background-color: $green-dark;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  /* The only rule that matters */
  #video-background {
    display: block;
    width: 100%;
    z-index: 0;
  }
}

@include media-breakpoint-up(md) {
  .video-header {
    .video-overlay {
      background-color: transparent;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .video-logo {
      justify-content: flex-start;
      padding: 2rem 2rem;

      img {
        width: 25%;
        max-width: 25%;
      }
    }

    .video-description {
      position: relative;
      padding: 0.75rem 1rem;
      color: $white;
      text-align: left;
      border-top: 1px solid $white;

      .btn {
        margin-top: 0;
      }

    }
  }
}

.video-wrapper {
  background-color: $black;
  margin-bottom: 1rem;
  height: 50%;
  position: relative;
  cursor: pointer;
  transition: height 0.85s;

  .video-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 99;
    color: white;
    .btn {
      width: 98px;
      height: 98px;
      padding: 0;
      line-height: 98px;
      border: 1px solid;
      border-radius: 49px;
      text-align: center;
      &:hover {
        background-color: rgba($white,0.2);
      }
    }
  }

  .video-overlay {
    width: 100%;
    height: 100%;
    opacity: 1;

    img {
      width: 100%;
      transition: opacity 0.3s;
    }
  }

  .video-text {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+70,000000+100&0+70,1+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 70%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 70%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 70%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */

    position: absolute;
    padding-left: 1rem;
    padding-bottom: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    z-index: 9;
  }

  .video-button {
    background: linear-gradient(rgba(#e6a161, 0.15), rgba(#e6a161, 0.1));
    position: absolute;
    width: 100%;
    height: 100%;
    color: $white;
    &:hover .btn {
      background-color: rgba(255,255,255,0.7);
      color:  $black; /* Button text color at hover */
      border: none;
    }
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 9;
  }
  .video-play-button {
    width: 68px;
    height: 68px;
    border: 1px solid theme-color("secundary");
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    span {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 5px;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      border-left: 24px solid theme-color("secundary");
    }
  }

  iframe,object,embed,video, .videoWrapper, .video-js {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
  }

  .video-js, img.vjs-poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%!important;
    height: 100%!important; //these need !important because IE wants to set height/width to 100px
    max-width: 100%;
    opacity: 0;
    transition: 0.3s;
  }

  &.pullout {
    position: fixed;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 999;
    transform: translateY(-50%);
    .video-overlay {
      position: absolute;
      opacity: 0;
      img {
        height: 100%;
      }
    }

    .video-button {
      z-index: 999;
      font-size: 0;
      &::before {

      }
    }

    iframe, object, embed, .videoWrapper, .video-js {
      opacity: 1;
    }
  }
  &:hover {
    .video-overlay {
      img {
        opacity: 0.75;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .video-wrapper {
    .video-overlay {
      z-index: 2;
    }
    .video-play-button {
      width: 128px;
      height: 128px;
      span {
        margin-left: 10px;
        border-top: 26px solid transparent;
        border-bottom: 26px solid transparent;
        border-left: 44px solid theme-color("secundary");
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .video-wrapper {
    .video-play-button {
      width: 188px;
      height: 188px;
      span {
        margin-left: 15px;
        border-top: 46px solid transparent;
        border-bottom: 46px solid transparent;
        border-left: 74px solid theme-color("secundary");
      }
    }
  }
  .video-header {
    .video-logo {
      padding: 2rem 4rem;
    }
    .video-description {
      padding: 1rem 3rem;
    }
  }
}

body {
  &.fixed {
    overflow: hidden;
  }
}

.arrow {
  float: right;
  transform: rotate(90deg);
  transition: all 0.5s ease;
}