.filter-wrapper {
  background-color: #eeeeee;
}

.md-input {
  *, :after, :before {
    box-sizing: border-box;
  }
  position: relative;
  display: inline-block;
  margin: 0;
  width: 100%;

  [type=text] {
    background: #eeeeee;
    border-color: #a5a5a5;
    border-width: 0 0 7px 0;
    border-bottom-style: solid;
    color: $grey-dark;
    cursor: default;
    display: block;
    padding: 1.5rem 0 1.5rem 1rem;
    margin-bottom: .5rem;
    position: relative;
    @extend %barlow;
    @include fluid-type($min_width, $max_width, (29px/1.4), 29px, (1.6/1.125), 1.6);
    text-align: left;
    text-shadow: none;
    width: 100%;
    height: 100px;
    z-index: 1;
    outline: none;
    overflow: hidden;

    &:focus, &:hover {
      cursor: pointer;
      border-color: #454545;
    }
  }
}

.md-select {
  *, :after, :before {
    box-sizing: border-box;
  }
  position: relative;
  display: inline-block;
  margin: 0;
  width: 100%;

  label {
    width: 100%;
  }

  [type=button] {
    background: #eeeeee;
    border-color: #a5a5a5;
    border-width: 0 0 7px 0;
    border-bottom-style: solid;
    color: $grey-dark;
    cursor: default;
    display: block;
    padding: 1.5rem 0 1.5rem 1rem;
    margin-bottom: .5rem;
    position: relative;
    @extend %barlow;
    @include fluid-type($min_width, $max_width, (29px/1.4), 29px, (1.6/1.125), 1.6);
    text-align: left;
    text-shadow: none;
    width: 100%;
    height: 100px;
    z-index: 1;
    outline: none;
    overflow: hidden;

    &:focus, &:hover {
      cursor: pointer;
    }
    &:after {
      content: '\25be';
      display: inline-block;
      vertical-align: middle;
      width: 50px;
      font-size: 50px;
      text-align: center;
      transform: scaleY(0.7);
      transition: 0.3s;
    }
  }
  ul[role=listbox] {
    background-color: white;
    cursor: default;
    list-style: none;
    line-height: 26px;
    overflow: hidden;
    margin: 0;
    max-height: 0;
    position: absolute;
    padding: 0;
    transition: all 0.15s cubic-bezier(0.35, 0, 0.25, 1);
    width: 100%;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.2) !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    li {
      height: 48px;
      margin: 0;
      padding: 10px 16px;
      outline: none;
      overflow: hidden;
      cursor: pointer;

      .custom-checkbox .btn {
        font-size: inherit;
      }
    }
  }

  &.active {
    [type=button] {
      border-color: #454545;

      &:after {
        transform: scaleY(0.7) rotate(180deg);
      }
    }
  }

  &.active ul {
    max-height: 300px;
    overflow: auto;
    padding: 8px 0 16px 0px;
    z-index: 2;
    transition: all .2s ease
  }
}

.project-wrapper {
  margin-top: 1.5rem;
  overflow: hidden;
}

.project {
  display: block;
  padding: 1rem 0;
  height: 100%;
  @include fluid-type($min_width, $max_width, (21px/1.125), 21px, (1.4/1.125), 1.4);
  color: $body-color;
  text-align: left;

  &__image {
    margin-bottom: 0.75rem;
  }

  &__place {
    margin-bottom: 0.5rem;
    span {
      color: $green-dark;
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  &__title {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  &__category {
    display: none;
  }

  &--highlighted {
    .project__image {
      flex: 2;
    }

    .project__place {
      @include fluid-type($min_width, $max_width, (29px/1.125), 29px, (1.1/1.125), 1.1);
    }

    .project__title {
      @include fluid-type($min_width, $max_width, (29px/1.125), 29px, (1.1/1.125), 1.1);
    }

    .project__text {
      position: relative;
      padding: 3rem 3rem 3rem 0;
      margin-top: 3rem;

      &:after {
        content: "";
        background-color: $grey;
        position: absolute;
        top: 0;
        left: -20%;
        width: 140%;
        height: 100%;
        z-index: -1;
      }
    }

    .project__category {
      margin-top: 2.5rem;
    }

    .project__body {
      flex: 3;
      align-self: flex-end;
    }
  }
}

.project {
  a {
    color: $body-color;
  }
  .project__image {
    margin-bottom: 0.75rem;

    figure {
      &:after {
        content: "";
        background-color: $green-dark;
        position: absolute;
        top: 100%;
        width: 100%;
        height: 100%;
        @include transition(all, 0.5s);
      }
    }
  }
  &:hover {
    .project__image {
      figure {
        .image-large {
          transform: scale(1.05);
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .project {
    &--highlighted {
      display: flex;
      flex-direction: row;
      .project__image {
        order: 2;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .md-select, .md-input {
    [type=button], [type=text] {
      padding: 1.5rem 0 1.5rem 0;
      text-align: center;
    }
  }

  .project {
    &__category {
      display: block;
    }
  }
}