body {
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
}

* {
  box-sizing: border-box;
}

.site-content {
  margin-bottom: 4rem;
}

.section {
  &--offset {
    padding-top: 3rem;
  }

  &--grey {
    background-color: $grey;
  }
}

a {
  text-decoration: none;
  color: $blue;
}

.custom-arrow {
  position: relative;
  display: flex;
  min-width: 10px;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    border-left: 2px solid $body-color;
    border-bottom: 2px solid $body-color;
    transform: rotate(45deg) translate3d( 0, 0, 1px);
  }
}

.page-loader {
  background-color: rgba($white,0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  @include transition(all, 0.3s);

  &__wrapper {
    display: flex;
    position: relative;
    width: 84px;
    height: 84px;
    flex-direction: column;
    justify-content: center;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 100px;
      height: 100px;
      border: 6px solid $blue-light;
      border-radius: 50%;
      animation: loader-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $blue-light transparent transparent transparent;
    }
  }

  &__wrapper div:nth-child(1) {
    animation-delay: -0.45s;
  }

  img {
    margin: auto;
    max-width: 64px;
    width: 64px;
  }
  @keyframes loader-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

body.show-loader {
  .page-loader {
    opacity: 1;
    z-index: 99;
  }
}

@include media-breakpoint-down(lg) {
  .container {
    max-width: none;
  }
}

@include media-breakpoint-up(md) {
  .site-content {
    margin-right: 1rem;
  }
}

@include media-breakpoint-up(xxl) {
  .site-content {
    margin-right: 2rem;
  }
  .container--center {
    max-width: 1300px;
  }
}