.gallery {
    margin: -0.6rem;
    overflow: hidden;

    a {
        display: inline-block;
        float: left;
        position: relative;
        padding: 0.6rem;
        width: 50%;
        text-decoration: none;

        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        -webkit-transition: transform 200ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
        transition: transform 200ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);

        &:hover {
            transform: scale(1.05);
        }

        &:focus {
            outline: none;
        }
    }
}

.gallery {
    &.one-image {
        a {
            width: 100%;
        }
    }
    &.two-image {
        a {
            width: 50%;
        }
    }
    &.three-image {
        a {
            width: 50%;

            figure {
                padding-bottom: 100%;
            }

            &:first-child {
                margin-bottom: 0.5rem;
                width: 100%;
                height: 270px;
                overflow: hidden;
            }
        }
    }
    &.more {
        a {
            width: 25%;

            figure {
                padding-bottom: 100%;
            }

            &:first-child {
                margin-bottom: 0.5rem;
                width: 100%;
                height: 270px;
                overflow: hidden;

                &:hover {
                    transform: scale(1.01);
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .gallery {
        &.three-image {
            a {
                &:first-child {
                    height: 500px;
                }
            }
        }
        &.more {
            a {
                &:first-child {
                    height: 500px;
                }
            }
        }
    }
}

.video-wrapper + .gallery.one-image {
    a {
        margin-bottom: 0;
        width: 50%;
        height: auto;
        overflow: auto;
    }
}

.video-wrapper + .gallery.two-image {
    a {
        margin-bottom: 0;
        width: 50%;
        height: auto;
        overflow: auto;
    }
}

.video-wrapper + .gallery.three-image {
    a {
        margin-bottom: 0;
        width: 33.333%;
        height: auto;
        overflow: auto;
    }
}

.video-wrapper + .gallery.more {
    a {
        margin-bottom: 0;
        width: 33.333%;
        height: auto;
        overflow: auto;
    }
}