//custom settings bootstrap components
@import "breadcrumb";
@import "pagination";

%barlow-condensed {
  font-family: 'Barlow Condensed', sans-serif;
}
// light: 300
// medium: 500
// bold: 700

%barlow-semi-condensed {
  font-family: 'Barlow Semi Condensed', sans-serif;
}
// 600

%barlow {
  font-family: 'Barlow', sans-serif;
}
// regular: 400
// semi-bold: 600
// extra-bold: 700

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1700px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1160px,
        xxl: 1500px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1:  $spacer,
                        2: ($spacer * 2),
                        3: ($spacer * 3),
                        4: ($spacer * 4),
                        5: ($spacer * 5),
                        6: ($spacer * 6),
                        7: ($spacer * 7),
                        8: ($spacer * 8),
                        9: ($spacer * 9),
                        10: ($spacer * 10),
                        11: ($spacer * 11),
                        12: ($spacer * 12),
                        13: ($spacer * 13)

                ),
                $spacers
);