aside {
  h3 {
    @include fluid-type($min_width, $max_width, (41px/1.35), 41px, 1, 1);
  }
  .sidebar-item {
    margin-bottom: 1.5rem;
  }
}

@include media-breakpoint-up(md) {
  aside {
    .sidebar-item {
      margin-bottom: 3.5rem;
    }
  }

}