.portfolio-wrapper {
  position: relative;
  &:after {
    content: "";
    background-color: $grey;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    z-index: -1;
  }

  .portfolio {
    &__title {
      padding: 2rem 1rem 0;
    }

    &__text {
      padding: 0 1rem 1rem;
      a {
        position: relative;
        &:after {
          content: "";
          display: inline-block;
          margin-left: 1.25rem;
          width: 7px;
          height: 7px;
          border-left: 2px solid;
          border-bottom: 2px solid;
          transform: rotate(-135deg);
          vertical-align: middle;
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .portfolio-wrapper {
    .portfolio {
      &__title {
        padding: 6rem 1rem 0;
      }

      &__text {
        padding: 0 1rem 2rem;
      }
    }
  }
}