.quicklink {
    background-color: $grey;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    @include transition(0.3s);

    &__image {
        margin-right: 1rem;
        width: 58px;
        height: 58px;
        min-width: 58px;
        border-radius: 29px;
    }

    &__text {
        @include fluid-type($min_width, $max_width, (20px/1.5), 20px, 1, 1);
    }

    &__title {
        position: relative;
        display: inline;
        @extend %barlow-semi-condensed;
        font-weight: 600;
        text-transform: uppercase;
        color: $body-color;
        &:after {
            content: "";
            display: inline-block;
            margin-left: 0.5rem;
            margin-bottom: 0.15rem;
            width: 7px;
            height: 7px;
            border-left: 2px solid;
            border-bottom: 2px solid;
            transform: rotate(-135deg);
            vertical-align: middle;
        }
    }

    &__subtitle {
        margin-top: 0.5rem;
        color: #747474;
    }
}

a.quicklink {
    &:hover {
        background-color: darken($grey,5%);
        .image-large {
            transform: scale(1.12);
        }
    }
}


@include media-breakpoint-up(md) {
    .quicklink {
        padding: 1.5rem;
        &__image {
            width: 70px;
            height: 70px;
            min-width: 70px;
            border-radius: 35px;
        }
    }
}

@include media-breakpoint-up(xxl) {
    .quicklink {
        flex-direction: row;
    }
}