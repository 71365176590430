.breadcrumb {
  margin: 0;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  li {
    @extend .breadcrumb-item;
    @include fluid-type($min_width, $max_width, (20px/1.125), 20px, (1.2/1.125), 1.2);
    text-transform: uppercase;

    a {
      color: inherit;
    }

    &.last {
      @extend .active;
    }
  }
}