.tabs {
  display: flex;
  color: black;
  vertical-align: middle;
  align-items: center;
}

.tab-container {
  width: 100%;
}

.tab-component {
  display: none;
}

.nav-tabs  {
  display: flex;
  color: grey;
  border-bottom: none;
  .nav-link {
    padding: 1rem 1.8rem;
    border: none;
    border-radius: 0;

    &.active{
      background-color: transparent;
      color: $black;
      border-left: 1px solid rgba(0,0,0,0.5);
      border-right: 1px solid rgba(0,0,0,0.5);
    }
    &:not(.active){
      color: rgba(#8d8994,50%);
      border-bottom: 1px solid rgba(0,0,0,0.5);
    }
    &:first-child {
      padding-left: 0;
      border-left: none;
    }
    &:last-child {
      border-right: none;
      flex: 1;
    }
  }
  .nav-link:hover {
    background-color: transparent;
    color: $primary;
  }
}

.tab-content {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.accordion {
  .card {
    background-color: transparent;
    border: none;
    &-header {
      background-color: transparent;
      border-color: rgba(0,0,0,0.5);
      color: rgba(#8d8994,50%);
      .custom-arrow {
        float: right;
        &:before {
          border-color: $body-color;
          transform: translate(-10px, 5px) rotate(-45deg);
          transition: all .5s ease;
        }
      }
      &[aria-expanded="true"] {
        color: $black;
        .custom-arrow {
          float: right;

          &:before {
            transform: translate(-10px, 5px) rotate(135deg);
          }
        }
      }
    }
    &-body {
      padding: 1.25rem;
    }
  }
}


@include media-breakpoint-up(md) {
  .accordion {
    display: none;
  }
  .tab-component {
    display: block;
  }
}