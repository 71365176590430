footer {
  h4 {
    margin-bottom: 0;
  }

  ul {
    padding: 0;
    margin-top: 0;
    list-style: none;

    li {
      a {
        color: $body-color;
      }
    }
  }
}

.footer-label {
  display: flex;
  align-items: flex-end;

  &__description {
    margin-bottom: 1.5rem;
    @extend %barlow-condensed;
    text-transform: uppercase;
  }

  &__image{
    margin-left: 1.5rem;
    img {
      max-width: 65px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .footer-label {
    justify-content: flex-end;
    margin-top: -4rem;
  }
}