.share {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    h4 {
      margin: 0 1.5rem 1.5rem;
    }
  }

  &__icons {
    ul {
      list-style: none;
      margin: 0 0 0 1rem;
      padding: 0;
      height: 32px;
      text-align: center;

      li {
        display: inline-block;
        height: 32px;
        padding: 0;
        margin-right: 0.25rem;
        text-align: center;

        &:before {
          display: none;
        }

        &:last-child {
          margin-right: 0;
        }

        a {
          line-height: 32px;
          height: 32px;
          display: inline-block;
          color: $body-color;

          i {
            background-color: transparent;
            display: inline-block;
            width: 32px;
            height: 32px;
            font-size: 32px;
            text-align: center;
          }

          &:hover {
            color: $green-dark;
          }
        }
      }
    }

    &.share--small {
      ul {
        height: 20px;

        li {
          height: 20px;

          a {
            line-height: 20px;
            height: 20px;

            i {
              width: 20px;
              height: 20px;
              font-size: 20px;
            }

          }
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .share {
    &__icons {
      ul {
        li {
          margin-right: 1rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .share {
    justify-content: flex-end;

    &__icons {
      ul {
        li {
          margin-right: 2rem;
        }
      }
      &.share--small {
        ul {
          li {
            margin-right: 1rem;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .share {
    flex-direction: row;
    &__title {
      h4 {
        margin: 0 1.5rem 0;
      }
    }
  }
}