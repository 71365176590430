.block {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 0;

  &__image {
    margin: 2rem auto;
    width: 80%;
    text-align: center;
  }

  &__content {
    position: relative;
    background-size: cover;
    background-position: center;
    background-color: $white;
    padding: 1rem;
    margin: 1rem 0;
    min-height: 30vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-flow: wrap;
    z-index: 0;
    word-break: break-word;

    &.block--center {
      align-items: center;
    }

    p {
      margin-bottom: 2rem;
    }

    .btn {
      margin: auto 0 1rem 0;
    }
  }

  &--blue {
    .block__image, .block__content {
      background-color: #f4f6f8;
    }
  }

  &--cta {
    .block__content {
      text-align: center;
      align-items: center;

      &:before {
        content: "";
        background-color: rgba(54,54,54,.7);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      .btn {
        margin: auto auto 2rem auto;
      }
    }

    .block__image {
      width: 45%;
      border-radius: 50%;
      border: 2px solid #fff;
    }

    h3 {
      margin-bottom: 2.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .block {
    &__content {
      padding: 2rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .block {
    &__content {
      padding: 3rem;
    }
    &--cta {
      .block__content {
        padding: 1rem;
      }
    }
  }
}