.usp {
  margin-bottom: 4rem;

  &__body {
    display: flex;
  }

  &__header {
    margin-bottom: 2rem;
  }

  &__body {
    margin-bottom: 2rem;
  }

  &__icon {
    background-color: $white;
    margin-top: 0.75rem;
    margin-right: 1rem;
    width: 78px;
    height: 78px;
    border-radius: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    i {
      @include fluid-type($min_width, $max_width, (44px/1.4), 44px, (1.8/1.4), 1.8);
    }
  }

  p {
    margin: 0;
  }
}

@include media-breakpoint-up(md) {
  .usp {
    &__icon {
      margin-right: 1.5rem;
      width: 88px;
      height: 88px;
      border-radius: 44px;
    }
  }
}