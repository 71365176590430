.image-header {
  position: relative;

  &:before {
    content: "";
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+50&0.4+0,0+50 */
    background: -moz-linear-gradient(45deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 50%); /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 50%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, rgba(0,0,0,0.4) 0%,rgba(0,0,0,0) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: none;
  }

  &-home {
    .image-header__background {
      margin-bottom: 2rem;
    }
  }

  &__background {
    position: relative;
    min-height: 46vw;
  }

  &__overlay {
    background-color: #489783;
    padding: 1rem;
    z-index: 9;
  }

  &__text {
    display: inline-block;
  }

  &__title {
    padding: 0.75rem 1rem;
    @extend %barlow-condensed;
    @include fluid-type($min_width, $max_width, (65px/2.5), 65px, 1, 1);
    color: $white;
    font-weight: 300;
    text-transform: uppercase;
    border: 3px solid;
  }
}

@include media-breakpoint-up(sm) {
  .image-header {
    &__title {
      border-width: 4px;
    }
  }
}

@include media-breakpoint-up(md) {
  .image-header {
    &:before {
      display: block;
    }

    &-home {
      .image-header__background {
        min-height: 64vw;
        margin-bottom: 0;
      }
    }

    &__background {
      min-height: 26vw;
    }

    &__overlay {
      background-color: transparent;
      padding: 2rem;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    &__title {
      padding: 1.5rem 2rem;
      border-width: 5px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .image-header {
    &-home {
      .image-header__background {
        min-height: 64vw;
      }
    }

    &__title {
      border-width: 6px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .image-header {
    &-home {
      .image-header__background {
        min-height: 40vw;
      }
    }

    &__overlay {
      padding: 3rem;
    }

    &__title {
      border-width: 8px;
    }
  }
}

.bg-progressive {
  background-color: #ddd;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
  z-index: 1;

  .background-image-small {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    filter: blur(50px);
    @include transition(all, 0.3s);
  }

  .background-image-large {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    @include transition(all, 0.3s);
  }

  .loaded {
    opacity: 1;
  }
}