figure.figure-progressive {
  background-color: $light;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  //16:9 resolution
  padding-bottom: 67%;
  margin: 0;

  //1:1 resolution
  &.figure-square {
    padding-bottom: 100%;
  }

  &.figure-project {
    padding-bottom: 137%;
  }

  &.figure-cta {
    padding-bottom: 50%;
  }

  &.figure-circle {
    padding-bottom: 100%;
    border-radius: 50%;
  }

  .image-small {
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    filter: blur(50px);
    @include transition(all, 0.3s);
  }

  .image-large {
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    @include transition(all, 0.3s);
  }

  .loaded {
    opacity: 1;
  }
}