body {
  @extend %barlow;
  @include fluid-type($min_width, $max_width, (20px/1.4), 20px, (1.5/1.125), 1.5);
  color: $body-color;
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 2.5rem;
}

h1,.h1 {
  margin-bottom: 2.5rem;
  @extend %barlow-condensed;
  @include fluid-type($min_width, $max_width, (48px/1.5), 48px, 1, 1);
  font-weight: 500;
  text-transform: uppercase;
}

h2,.h2 {
  @extend %barlow-condensed;
  @include fluid-type($min_width, $max_width, (40px/1.5), 40px, 1, 1);
  font-weight: 500;
  text-transform: uppercase;
}

h3,.h3 {
  @extend %barlow-condensed;
  @include fluid-type($min_width, $max_width, (32px/1.35), 32px, 1, 1);
  font-weight: 500;
  text-transform: uppercase;
}

h4,.h4 {
  @extend %barlow-condensed;
  font-weight: 700;
  text-transform: uppercase;
}

h5,.h5,h6,.h6 {
  @include fluid-type($min_width, $max_width, 18px, 18px, 1, 1);
}