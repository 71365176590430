.cta {
  position: relative;
  display: block;
  margin-bottom: 1rem;

  &__text {
    background-color: $white;
    padding: 1rem 1.5rem;
    max-width: 85%;
    @include fluid-type($min_width, $max_width, (22px/1.5), 22px, 1, 1);
    color: $body-color;
    @include transition(0.3s);
    transform: translateY(-2.5rem);
    &:before {
      content: "";
      position: absolute;
      right: 1.5rem;
      top: calc(50% - 10px);
      display: block;
      width: 20px;
      height: 20px;
      border-left: 5px solid;
      border-bottom: 5px solid;
      border-top: transparent;
      transform: rotate(-135deg) translate3d( 0, 0, 1px);
    }
  }

  &__title {
    margin-bottom: 0.5rem;
    @extend %barlow-condensed;
    text-transform: uppercase;
  }

  &__subtitle {
    display: block;
  }

  &--highlighted {
    .cta__text {
      background-color: $green-dark;
      color: $white;
      &:before {
        border-color: $white;
      }
    }
  }
}

a.cta {
  &:hover {
    .cta__image {
      .image-large {
        transform: scale(1.05);
      }
    }

    .cta__text {
      max-width: 90%;
    }
  }
}

@include media-breakpoint-up(md) {
  .cta {
    &--offset {
      margin-top: -3rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .cta {
     &--offset {
      margin-top: -10rem;
    }
  }
}

@include media-breakpoint-up(xl) {
  .cta {
    margin-bottom: 3rem;

    &__text {
      padding: 2rem 3rem;
      &:before {
        right: 2.5rem;
      }
    }
  }
}