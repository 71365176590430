.author {
  margin-bottom: $spacer;
  display: flex;
  align-items: center;
  .author-image{
    width: 50px;
    margin-right: $spacer;
  }

  .post-meta {
    .post-name {

    }
    .post-date {
      color: $primary;
    }
  }
}