.news-wrapper {

  .news-item-wrapper {
    position: relative;
  }

  &__title {
    padding: 2rem 1rem 0;
  }

  &__text {
    padding: 0 1rem 1rem;
    a {
      position: relative;
      &:after {
        content: "";
        display: inline-block;
        margin-left: 1.25rem;
        width: 7px;
        height: 7px;
        border-left: 2px solid;
        border-bottom: 2px solid;
        transform: rotate(-135deg);
        vertical-align: middle;
      }
    }
  }
}

//news component homepage
.news-item-wrapper--home {
  overflow: hidden;
  .news {
    position: relative;
    display: block;
    width: 100%;
    float: left;
    overflow: hidden;

    &__overlay {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      bottom: 0%;
      left: 0;
      width: 100%;
      padding: 1rem 1rem;
      z-index: 9;
      @extend %barlow-condensed;
      @include fluid-type($min_width, $max_width, (26px/1.6), 28px, (1.25/1.125), 1.25);
      font-weight: 300;
      color: $white;
      transition: .6s cubic-bezier(.49,1,.22,1) 0.7ms;

      &:before {
        content: "";
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+40,000000+100&0+40,0.4+100 */
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.4) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.4) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#66000000', GradientType=0); /* IE6-9 */
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      &:after {
        content: "";
        background-color: $green-dark;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      span {
        @extend %barlow;
        @include fluid-type($min_width, $max_width, (22px/1.4), 22px, (1.25/1.125), 1.25);
        font-weight: 600;
      }

      p {
        margin: 0;
      }
    }
  }

  a.news {
    background-color: $green-dark;

    &:hover {
      .image-large {
        transform: scale(1.025);
      }
    }
  }
}

//news overview page
.news-item-wrapper {
  overflow: hidden;
  .news {
    display: block;
    position: relative;
    margin-bottom: 2rem;

    &__overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 3rem 2rem;
      color: $white;
      z-index: 9;
      @include transition(opacity, 0.3s);

      &:before {
        content: "";
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+40,000000+100&0+40,0.6+70 */
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.6) 70%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.6) 70%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.6) 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#66000000', GradientType=0); /* IE6-9 */
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }

      span {
        @extend %barlow;
        @include fluid-type($min_width, $max_width, (25px/1.4), 25px, (1.25/1.125), 1.25);
        font-weight: 600;
      }

      p {
        @extend %barlow-condensed;
        @include limit-lines($min_width, $max_width, (28px/1.4), 28px, 1, 1.25, 2);
        font-weight: 300;
        margin: 0;
        overflow: hidden;
      }
    }
  }

  a.news {
    &:hover {
      .image-large {
        transform: scale(1.025);
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .news-wrapper {
    &__text {
      padding: 0 1rem 2rem;
    }
  }
  .news-item-wrapper--home {
    a.news {
      width: 50%;
      .news__overlay {
        height: 100%;
        bottom: auto;
        top: 100%;
        padding: 2rem 2rem;
      }

      &:hover {
        .news__overlay {
          top: 0;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .news-item-wrapper--home {
    a.news {
      width: 25%;
      .news__overlay {
        padding: 1rem 1rem;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .news-item-wrapper--home {
    a.news {
      .news__overlay {
        padding: 3rem 2rem;
      }
    }
  }
}